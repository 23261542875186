<template>
  <div class="h-full w-full grid grid-cols-3 xl:grid-cols-4 gap-4">
    <div class="pt-5 overflow-auto hidden xl:block">
      <sgdf-participant-list :participants="interns" :skeleton="!isSuccess" :currentId="participant?.id" mode="none"
        :sortField="route.query.sortField" :isSortAsc="route.query.isSortAsc"
        @sgdf-participant:navigate="onParticipantClick" @sgdf-participants-sort:change="onSortChange"
        @sgdf-participants-sort-dir:change="onSortDirChange"></sgdf-participant-list>
    </div>
    <div class="col-span-3 px-4 xl:px-0 overflow-hidden relative">
      <div class="absolute inset-x-0 bottom-5 z-10 space-x-5 w-full justify-center flex xl:hidden"
        v-if="isSuccessParticipant">
        <sgdf-button .disabled="!hasPrevious" @click="onPrev">
          Précédent
        </sgdf-button>
        <sgdf-button .disabled="!hasNext" @click="onNext">
          Suivant
        </sgdf-button>
      </div>

      <div class="absolute top-2 right-5 z-10 hidden gap-2 flex-col items-end xl:flex">
        <div v-if="isSuccessParticipant" class="flex space-x-2">
          <sgdf-button .disabled="!hasPrevious" @click="onPrev">
            Précédent
          </sgdf-button>
          <sgdf-button .disabled="!hasNext" @click="onNext">
            Suivant
          </sgdf-button>
        </div>
        <sgdf-toggle .value="route.query.view" .choices="choices" @sgdf-input="onViewClick"></sgdf-toggle>
      </div>
      <div class="h-full overflow-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import "@sgdf/ui-library/sgdf-participant-list.js";
import { useSortParticipants } from "~/composables/sort-participant";

const choices = [
  { label: "Observations", value: "comment" },
  { label: "Détail", value: "detail" },
  { label: "Validation", value: "validation" },
];

const {
  data: participant,
  isSuccess: isSuccessParticipant,
  status,
} = useCurrentStagiaire();

const { data: participants, isSuccess } = useInterns();


const {
  interns,
  route,
} = useSortParticipants({
  participants
})

const meetingId = useMeetingId();

const onSortChange = async (e: CustomEvent<{ sortField: string }>) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      sortField: e.detail.sortField,
    })
  );
};

const onSortDirChange = async () => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      isSortAsc: route.query.isSortAsc === "true" ? "false" : "true",
    })
  );
};


watchEffect(() => {
  if (status.value === "error") {
    navigateTo(RouteUtils.getMeetingParticipants(meetingId.value!));
  }
});



const hasNext = computed(() => {
  return (
    interns.value.findIndex((p) => p.id === participant?.value?.id) <
    interns.value.length - 1
  );
});

const hasPrevious = computed(
  () => interns.value.findIndex((p) => p.id === participant?.value?.id) > 0
);

const onNext = async () => {
  if (!participant.value) return;
  const index = interns.value.findIndex((p) => p.id === participant.value.id);
  if (index < interns.value.length - 1) {
    await navigateTo(
      RouteUtils.getMeetingParticipant(meetingId.value!, {
        ...route.query,
        participantId: interns.value[index + 1].id,
      })
    );
  }
};

const onPrev = async () => {
  if (!participant.value) return;
  const index = interns.value.findIndex((p) => p.id === participant.value.id);
  if (index > 0) {
    await navigateTo(
      RouteUtils.getMeetingParticipant(meetingId.value!, {
        ...route.query,
        participantId: interns.value[index - 1].id,
      })
    );
  }
};

const onParticipantClick = async (
  e: CustomEvent<{
    participantId: string;
    action: "check-in" | "validation" | "interview" | "link";
  }>
) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      participantId: e.detail.participantId,
    })
  );
};

const onViewClick = async (e: CustomEvent<string>) => {
  await navigateTo(
    RouteUtils.getMeetingParticipant(meetingId.value!, {
      ...route.query,
      view: e.detail,
    })
  );
};
</script>
